import IconCheck from '@/assets/icons/icon-check.svg?component';
import IconCutlery from '@/assets/icons/icon-cutlery.svg?component';
import { type PropType, type VNode, defineComponent, h } from 'vue';

export default defineComponent({
  name: 'ProgressBar',
  props: {
    steps: {
      type: Array as PropType<string[]>,
      required: true,
    },
    translationKey: {
      type: String,
      required: true,
    },
    activeStep: {
      type: Number,
      required: true,
    },
    variant: {
      type: String as PropType<'simple' | 'numbered'>,
      required: true,
    },
    lastStepIcon: {
      type: Object as PropType<VNode>,
      default: () => h(IconCutlery),
    },
  },
  render() {
    const totalSteps = this.steps.length;
    const { activeStep, variant, lastStepIcon } = this;

    return (
      <div class="flex justify-center w-full relative px-4 pt-2 pb-10">
        <div class="relative flex items-center w-full max-w-full">
          <div class="absolute top-1/2 left-0 w-full h-2 bg-linear-to-r from-gray-50 to-gray-300 rounded-lg -translate-y-1/2 z-0"></div>

          <div class="flex justify-between w-full z-10 relative">
            {this.steps.map((step, index) => {
              const isActive = index === activeStep;
              const isComplete = index < activeStep;
              const isLastStep = index === totalSteps - 1;

              return (
                <div
                  key={index}
                  class="flex flex-col items-center justify-center relative"
                >
                  <div
                    class={`h-8 w-8 rounded-full border-3 border-rb-lighter-gray flex items-center justify-center bg-gray-300
                      ${isLastStep && 'bg-rb-yellow'}
                      ${(isActive || isComplete) && 'bg-rb-green'}`}
                  >
                    {variant === 'numbered' &&
                      (isComplete ? (
                        <IconCheck class="text-white" />
                      ) : isLastStep ? (
                        <span class="text-rb-green h-3 w-3">
                          {lastStepIcon}
                        </span>
                      ) : (
                        <span class="text-white text-sm">{index + 1}</span>
                      ))}
                  </div>

                  <div class="absolute top-full mt-2 w-max text-center">
                    <span
                      class={`text-xs ${isActive ? 'text-rb-green font-medium' : 'text-gray-300'}`}
                    >
                      {this.$t(`${this.translationKey}.${step}`)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            class="absolute top-1/2 left-0 h-2 bg-rb-green rounded-lg -translate-y-1/2"
            style={{ width: `${(activeStep / (totalSteps - 1)) * 100}%` }}
          ></div>
        </div>
      </div>
    );
  },
});
