import IconCampaign from '@/assets/icons/icon-campaign.svg?component';
import ActionBar from '@/components/ActionBar';
import MainContent from '@/components/MainContent';
import MainFooter from '@/components/MainFooter';
import MainHeader from '@/components/MainHeader';
import NavBarMinimal from '@/components/NavBarMinimal';
import ProgressBar from '@/components/ProgressBar';
import TrackingConsentForm from '@/components/TrackingConsentForm';
import type { VNode } from 'vue';

const steps = ['store', 'register', 'address', 'payment', 'recipes'];

export default defineNuxtComponent({
  name: 'StoreLayout',
  setup() {
    useHead({
      htmlAttrs: {
        'data-layout': 'store',
      },
    });
    const { t } = useI18n();
    const route = useRoute();
    const activeStep = computed(() =>
      steps.findIndex((step) => step.toLowerCase() === route.name)
    );
    const modalStore = useModalStore();
    const { hasSetConsent, hasMarketingConsent } = useTrackingConsent();
    const hasGivenConsent = computed(() => hasSetConsent());
    const { initTracklution, sendEvent } = useTracklution();

    const showDiscountBanner = computed(() =>
      ['store', 'postal-code'].includes(String(route.name))
    );
    const { redeemDiscountCode } = useCustomerApi();

    const promoCode = computed(() =>
      route.query.promo && showDiscountBanner.value
        ? String(route.query.promo)
        : undefined
    );

    const { data: campaignWithCode } = redeemDiscountCode(promoCode);

    watch(hasMarketingConsent, (value) => {
      if (value) {
        initTracklution();
        sendEvent(TrackingEvent.ViewContent);
      }
    });

    onMounted(() => {
      if (hasGivenConsent.value === false) {
        modalStore.openModal(ModalType.Generic, {
          title: t('tracking_consent_form.modal_title'),
          content: (
            <TrackingConsentForm
              isOnboarding={true}
              onDone={() => modalStore.closeModal(ModalType.Generic)}
            />
          ),
          actions: null,
          dismissable: false,
        });
      }
    });

    return {
      activeStep,
      campaignWithCode,
      showDiscountBanner,
    };
  },
  render(): VNode {
    return (
      <>
        <MainHeader>
          <NavBarMinimal showSignOut={false} class="py-4 sm:py-0" />
        </MainHeader>
        <div class="progress-bar bg-rb-lighter-gray">
          <div class="mx-auto max-w-md sm:max-w-lg">
            <ProgressBar
              translationKey="store.steps"
              steps={steps}
              activeStep={this.activeStep}
              variant="numbered"
            />
          </div>
        </div>
        {this.showDiscountBanner && this.campaignWithCode?.campaign && (
          <ActionBar
            type="credits"
            class="w-full"
            wrapperClasses={{ 'flex-col!': true }}
          >
            <div class="text-content text-center">
              <h2 class="title flex text-sm gap-1">
                <IconCampaign class="icon h-6 w-6" />
                {this.$t('store.discount_x_applied', {
                  discountTitle: this.campaignWithCode.campaign.title,
                })}
              </h2>
            </div>
          </ActionBar>
        )}
        <MainContent class="mx-auto p-4 sm:px-6 lg:px-8 max-w-md sm:max-w-lg lg:max-w-xl">
          {this.$slots.default?.()}
        </MainContent>
        <MainFooter />
      </>
    );
  },
});
